@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/roboto-v20-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/roboto-v20-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/roboto-v20-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/roboto-v20-latin-500.svg#Roboto')
      format('svg'); /* Legacy iOS */
}

body {
  max-width: 1024px;
  min-height: 100vh;
  margin: auto !important;
  box-shadow: 0 8px 24px rgba(38, 202, 153, 0.24);
}
