.card {
  position: relative;
  margin: auto;
  margin-top: 15px;
  max-width: 500px;
}

.row {
  display: flex;

  & > p {
    min-width: 110px;
    flex-basis: 110px;
    padding-right: 10px;
  }

  & > div {
    height: fit-content;
    max-width: calc(100% - 110px);

    & > input,
    & > span {
      max-width: 100%;
    }
  }
}

.value {
  font-weight: bold;
}

.buttonSection {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 0.5rem;
  }

  .QRButton {
    width: 30px;
  }
}
