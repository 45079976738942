.container {
  background-color: #ffffff;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
}

.container:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

.innerContainer {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.text {
  padding-left: 24px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.54);
}

.icon {
  height: 18px;
}
