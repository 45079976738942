.outerContainer {
  display: flex;
  flex-direction: column;

  .innerContainer {
    margin: auto;
    min-width: 100px;
    max-width: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .header {
      margin: 0 0 15px 0;
      text-align: center;
    }

    .label {
      & > label {
        min-width: 75px;
      }

      & > div {
        min-width: 100px;
        width: 100%;
      }
    }

    .bottomContainer {
      display: flex;

      .tooltip {
        width: 100%;

        > span {
          width: 100%;
        }
      }

      .generateButton {
        text-align: center;
        padding: 5px 10px;
        margin-left: 24px;
        flex-basis: 120px;
      }
    }
  }
}
